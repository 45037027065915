<template>
  <t-page>
    <template #header>
      <h3 slot="header">
        {{ $t('fields.invitations') }}
      </h3>
      <t-button v-if="canCreate" type="primary" @click="modalCreateInviteVisible = true">
        {{ $t('pages.invitation.actions.add_new') }}
      </t-button>
      <portal to="modals">
        <modal-save-invite
          v-if="modalCreateInviteVisible"
          @created="onCreated"
          @close="modalCreateInviteVisible = false"
        />
      </portal>
    </template>
    <t-grid>
      <t-grid-cell>
        <invitations-index-table ref="datatable" :query="query" />
      </t-grid-cell>
    </t-grid>
  </t-page>
</template>

<script>
import Vue from 'vue';
import Invitation from '~/models/Invitation';
import InvitationsIndexTable from '~/pages/shared/invitations/partials/InvitationsIndexTable';
import ModalSaveInvite from '~/components/ModalSaveInvite';

export default Vue.extend({
  components: {
    ModalSaveInvite,
    InvitationsIndexTable,
  },

  data: () => ({
    modalCreateInviteVisible: false,
  }),

  fetch() {
    if (this.$route.query['show-create-modal']) {
      this.modalCreateInviteVisible = true;
    }
  },

  computed: {
    query() {
      return () => new Invitation().where('organization_id', this.$auth.me?.organization_id ?? this.$route.params.id);
    },

    canCreate() {
      return this.$ability.can('create', 'Invitation');
    },
  },

  methods: {
    onCreated() {
      this.$refs.datatable.fetchResources();
    },
  },
});
</script>
