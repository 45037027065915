import { filterTypes } from '~/enums/FilterTypes';
import { InvitationStateFilter } from '~/components/Filters';

export default [
  {
    key: 'state',
    component: InvitationStateFilter,
    type: filterTypes.ARRAY,
  },
];
