<template>
  <t-modal class="modal-save-invite" size="sm" @modal-close="handleClose">
    <a href="#" class="t-modal__close" role="button" @click.prevent="handleClose">
      <t-icon icon="times" />
    </a>
    <div class="t-modal__header">
      <span class="t-modal__title">
        {{
          isUpdate ? $t('pages.invitation.actions.update') : $t('pages.invitation.actions.add_new')
        }}
      </span>
    </div>
    <validation-observer ref="validator" v-slot="{ invalid, handleSubmit }" tag="div">
      <t-form @submit.native.prevent="handleSubmit(submit)">
        <div class="t-modal__body">
          <t-validation-wrapper
            vid="email"
            :label="$t('fields.email_address')"
            :name="$t('fields.email_address')"
            rules="required|email"
          >
            <t-checked-email v-model="form.email" />
          </t-validation-wrapper>
          <t-grid>
            <t-grid-cell>
              <t-validation-wrapper
                vid="meta.firstname"
                :name="$t('fields.first_name')"
                :label="$t('fields.first_name')"
                rules="string"
              >
                <t-input
                  v-model="form.meta.firstname"
                  type="text"
                  :placeholder="$t('fields.first_name')"
                />
              </t-validation-wrapper>
            </t-grid-cell>
            <t-grid-cell>
              <t-validation-wrapper
                vid="meta.lastname"
                :name="$t('fields.last_name')"
                :label="$t('fields.last_name')"
                rules="string"
              >
                <t-input
                  v-model="form.meta.lastname"
                  type="text"
                  :placeholder="$t('fields.last_name')"
                />
              </t-validation-wrapper>
            </t-grid-cell>
          </t-grid>
          <t-validation-wrapper vid="meta.gender" :name="$t('fields.gender')" rules="string">
            <gender-select v-model="form.meta.gender" />
          </t-validation-wrapper>
        </div>
        <div class="t-modal__footer">
          <t-button-loading native-type="submit" block :loading="loading" :disabled="invalid">
            {{ $t('global.actions.save') }}
          </t-button-loading>
        </div>
      </t-form>
    </validation-observer>
  </t-modal>
</template>
<script>
import TValidationWrapper from '~/components/TValidationWrapper';
import TCheckedEmail from '~/components/Fields/TCheckedEmail';
import GenderSelect from '~/components/EnumSelect/GenderSelect';
import Invitation from '~/models/Invitation';

export default {
  components: {
    TValidationWrapper,
    TCheckedEmail,
    GenderSelect,
  },

  props: {
    invitation: {
      type: Object,
      required: false,
      default: null,
    },
  },

  data: () => ({
    form: null,

    loading: false,
  }),

  computed: {
    isUpdate() {
      return !!this.invitation;
    },
  },

  created() {
    this.form = this.getForm(this.invitation);

    if (!this.form.organization_id) {
      if (this.$auth.me.user.type === 'employee') {
        this.form.organization_id = this.$auth.me.organization_id;
      }

      if (['administrator', 'tenant_member'].includes(this.$auth.me.user.type)) {
        this.form.organization_id = this.$route.params.id;
      }
    }
  },

  methods: {
    getForm(model) {
      return {
        email: model?.email,
        meta: {
          firstname: model?.meta?.firstname,
          lastname: model?.meta?.lastname,
          gender: model?.meta?.gender,
        },

        organization_id: model?.organization_id,
      };
    },

    async submit() {
      this.loading = true;
      try {
        let invite = null;

        if (this.isUpdate) {
          invite = await this.update();
          this.$emit('updated', invite);
        } else {
          invite = await this.create();
          this.$emit('created', invite);
        }

        this.$emit('saved', invite);

        this.handleClose();
      } catch (e) {
        this.$axios.handleError(e, this.$refs.validator);
      } finally {
        this.loading = false;
      }
    },

    async create() {
      const invite = await new Invitation({ ...this.form }).save();
      await this.$notify.success(this.$t('notifications.invitation.created'));

      return invite;
    },

    async update() {
      const invite = await new Invitation({
        id: this.invitation.id,
        ...this.form,
      }).patch();

      await this.$notify.success(this.$t('notifications.invitation.updated'));

      return invite;
    },

    handleClose() {
      this.$emit('close');
    },
  },
};
</script>
